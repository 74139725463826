.notif-menu-item-main {
    width: 400px !important;
    background: rgb(255, 255, 255) !important;
    padding: 0 !important;
  }
  
  .w-max {
    width: 100%;
  }
  
  #scrollableDiv {
    height: 600px !important;
    overflow: auto !important;
  }
  
  .infiscroll-menu-item {
    /* height: 100px !important; */
    margin: 6px !important;
    padding: 8px !important;
    flex-grow: 1 !important;
  }
  
  .infiscroll-menu-item:active {
    background-color: black;
  }
  
  #scrollableDiv::-webkit-scrollbar {
    width: 7px !important; /* width of the entire scrollbar */
  }
  
  #scrollableDiv::-webkit-scrollbar-track {
    background: #c4c3c2 !important; /* color of the tracking area */
  }
  
  #scrollableDiv::-webkit-scrollbar-thumb {
    background-color: #256141 !important; /* color of the scroll thumb */
    border-radius: 20px !important; /* roundness of the scroll thumb */
  }
  
  .notif-skeleton {
    padding: 6px !important;
  }
  
  .MuiPaper-root > ul {
    padding-right: 0 !important;
    padding-bottom: 0 !important;
  }
  
  .notif-header {
    font-size: 14px !important;
    font-weight: 600 !important;
    padding: 10px !important;
  }
  
  .notif-footer {
    font-size: 14px !important;
    font-weight: 600 !important;
    padding: 10px !important;
    cursor: pointer;
  }
  
  .infiscroll-menu-item-active {
    background: #e5f4ee !important;
    border-radius: 5px !important;
  }
  
  .infiscroll-menu-item-inactive {
    background: #d9dbdb !important;
    border-radius: 5px !important;
  }
  
  .notif-acc-circle {
    height: 40px !important;
    width: 40px !important;
  }
  
  .notif-item-msg {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: normal;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
  
  .notif-item-date {
    color: #868686;
    font-family: "Poppins", sans-serif;
    font-size: 11px;
    font-weight: normal;
    word-wrap: break-word;
    white-space: pre-wrap;
  }