.clientListRoot {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    font-family: 'Poppins', sans-serif;
    margin-left: -22px !important;
    margin-top: -20px !important;
}

.clientListpaper {
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 49px 52px 40px 71px;
    /* border: 1px solid black; */
}

.clientListpaper2 {
    height: 100%;
    width: 100%;
    padding: 60px 31px 40px 0;
    /* border: 1px solid black; */
}

.clientListtableWidth {
    width: 100%;
}

.clientListtitleText{
    font-size: 29px;
}

.clientListdescription {
    font-size: 14px;
    font-weight: normal !important;
}

.clientListaddButton {
    width: 160px;
    height: 45px;
    color: #FFFFFF !important;
    background-color: #C8932A !important;
    text-transform: capitalize !important;
    margin-top: 28px !important;
}

.clientListaddButton:hover {
    background-color: #A67717 !important;
}

.clientListlabelUser {
    font-size: 13px !important;
    margin-top: 69px !important;
    letter-spacing: 0 !important;
}

.clientListformControl {
    margin-top: 7px !important;
    width: 270px;
}

.clientListformControl Select {
    height: 25px;
    font-size: 15px;
}

.clientListtextField {
    width: 240px;
    margin-bottom: 25px !important;
    float: right;
    text-transform: capitalize !important;
}

.clientListpagination {
    color: '#444444';
    font-weight: bold;
}

.clientListdialogLabel {
    font-size: 20px;
    font-weight: bold;
}

.clientListdialogLabel2 {
    font-size: 24px;
    font-weight: bold;
    color: #256141;
}

.clientListdialogLabel3 {
    margin-top: 15px;
    font-size: 14px;
}

.clientListdialogLabel4{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.clientListtextboxs {
    width: 353px;
    font-size: 16px;
}

.clientListdeleteBtn {
    width: 380px;
    height: 50px;
    margin: 0 24px 10px 24px; 
    background-color: #ED5555;
    color: white;
    font-size: 14px;
    text-transform: capitalize;
}

.clientListdeleteBtn:hover {
    background-color: #b83535;
}

.clientListcancelBtn {
    font-size: 14px;
    width: 380px;
    height: 50px;
    text-transform: capitalize;
    margin-bottom: 20px;
    margin-left: 24px;
}

@media only screen and (max-width: 1500px){
    .clientListpaper {
        padding: 40px 52px 40px 50px;
    }
    
    .clientListpaper2 {
        padding: 60px 25px 40px 0;
    }
    
    .clientListtitleText{
        font-size: 25px;
    }
    
    .clientListdescription {
        font-size: 12px;
    }
    
    .clientListlabelUser {
        font-size: 12px !important;
    }
    
    .clientListformControl {
        width: 240px;
    }
    
    .clientListformControl Select {
        height: 20px;
        font-size: 13px;
    }
    
    .clientListtextField {
        width: 240px;
    }

    .clientListTextFont{
        font-size: 13px;
    }
}

@media only screen and (max-width: 1366px) {
    .clientListpaper {
        padding: 40px 52px 40px 40px;
    }
    
    .clientListpaper2 {
        padding: 60px 15px 40px 0;
    }
    
    .clientListtitleText{
        font-size: 22px;
    }
    
    .clientListdescription {
        font-size: 10px;
    }
    
    .clientListlabelUser {
        font-size: 10px !important;
    }
    
    .clientListformControl {
        width: 200px;
    }
    
    .clientListformControl Select {
        font-size: 11px;
    }
    
    .clientListtextField {
        width: 240px;
    }

    .clientListTextFont{
        font-size: 11px;
    }
}

@media only screen and (max-width: 599px) {
    .clientListpaper {
        padding: 30px 40px 30px 30px;
    }
    
    .clientListpaper2 {
        padding: 40px 10px 40px 0;
    }
}