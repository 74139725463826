body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 720px) {
  .r-title {
    font-size: 28px !important;
    font-weight: 600;
    margin: 0;
  }
}

.r-title-container {
  padding-top: 15%;
}

.r-title {
  font-size: 48px;
  font-weight: 600;
  margin: 20px;
}

.text-center {
  text-align: center !important;
}

.r-subtitle {
  font-size: 20px;
  font-weight: normal;
  margin: 20px;
}