body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

a{
  text-decoration: none;
}

input {
  /* font-family: "Poppins", sans-serif !important; */
  font-size: 14px;
}

html {
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.login-bg{
  background: rgb(37,97,65);
  height: 100vh; 
  width: 100%;
  background-image: url('./img/BG.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.login{
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: auto;
  background: white;
  position: relative;
  top: 150px;
}

.login.client{
  width: 30%;
  display: flex;
  justify-content: space-between;
}

.login.admin{
  padding: 50px;
  width: 30%;
}

.login.client .left{
  
  padding: 50px;
}

.login.client .right{
  width: 50%;
  background: #FFF6E3;
  padding: 50px;
}

.login img{
  height: 50px;
  margin-bottom: 20px;
}

.login button{
  color: white;
  font-weight: bold;
  border-radius: 5px;
  text-transform: none;
}

.login .sign-with{
  color: #9F9F9F;
}

.btn-google{
  display: inline;
  border: 1px solid #9F9F9F;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  color: #9F9F9F;
  cursor: pointer;
}

.btn-google img{
  height: 20px;
  margin: -3px 10px -3px 0px;
}

.flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex .border{
  background: #9F9F9F;
  width: 70%;
  height: 1px;
}

.latest-news{
  background: white;
  height: 140px;
  margin-bottom: 10px;
  display: flex;
  overflow: hidden;
}


.latest-news img{
  height: 140px;
}

.latest-news .text{
  padding: 20px;
  height: 140px;
  font-size: 12px;
}

._title{
  color: #0D242C;
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

._subtitle{
  color: #444444;
  font-size: 16px;
  font-weight: 600;
}

.link{
  color: #C8932A !important;
  text-decoration-line: underline !important;
  cursor: pointer;
}

@media only screen and (max-width: 1700px) {
  .login{
    top: 10px;
  }
  
  .login.client {
    width: 40%;
  }

  .login.admin{
    width: 50%;
  }
}

@media only screen and (max-width: 982px) {
  .login .right{
    display: none;
  }

  .login .left{
    width: 100%;
  }
}

.r-title-container {
  padding-top: 15%;
}
.r-title {
  font-size: 48px;
  /* font-weight: 600; */
  margin: 20px;
}

.text-light {
  color: #fff;
}

.text-light-1 {
  color: #87b39b;
}

@media only screen and (max-width: 720px) {
  #message {
    margin: auto;
    width: 100%;
    height: 100%;
  }
  .r-title {
    font-size: 30px;
    font-weight: 600;
    margin: 20px;
  }
}

.text-center {
  text-align: center;
}

.r-subtitle {
  font-size: 20px;
  font-weight: normal;
  margin: 20px;
}

#message {
  margin: auto;
  width: 720px;
}

.logout{
  position: absolute !important;
  right: 0;
  color: white !important;
  border: none !important;
}

.not-verified {
  padding: 15px;
  display: flex;
  align-items: center;
}

.not-verified a {
  color: white;
  text-decoration: none;
}

.not-verified span {
  margin-left: 10px;
}

.body-content {
  padding: 10px;
}

._title {
  color: #0d242c;
}

.total-earning {
  color: #c8932a;
  display: flex;
}

.as-of {
  color: #444444;
}

.totals {
  margin-top: 20px;
}

.totals .total {
  display: flex;
  height: 50px;
}

.totals .total .icon {
  color: white;
  text-align: center;
  width: 50px;
  padding: 10px 10px;
  border-radius: 100%;
}

.totals .invested .icon {
  background: #256141;
}

.totals .depreciation .icon {
  background: #c8932a;
}

.totals .risk .icon {
  background: #ed5555;
}

.totals .total .icon svg {
  position: relative;
  top: 2px;
}

.totals .total .amount {
  margin-left: 10px;
}

.totals .total .amount .title {
  color: #444444;
  font-size: 14px;
}

.totals .total .amount .number {
  color: #0d242c;
  font-size: 20px;
  font-weight: bold;
}

.d-flex {
  display: flex;
  justify-content: space-between;
}

.my-investments {
  margin-top: 40px;
}

.investment-box {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px;
  margin-top: 30px;
}

.investment-box .title {
  font-weight: bold;
}

.investment-box .percentage {
  display: flex;
  align-items: center;
}

.investment-box table {
  width: 100%;
}

.investment-box .down {
  color: #ed5555;
}

.investment-box .up {
  color: #256141;
}

.rampver-color {
  color: #256141;
}

.rampver-bgColor {
  background: #256141;
  color: white;
}

.pie {
  margin-bottom: 30px;
}

.net-asset {
  padding: 20px;
  background: #f8f8f8;
  border-radius: 5px;
}

.content-margin {
  /* background: red; */
  padding: 20px;
}

.net-asset .title {
  display: flex;
}

.net-asset .title svg {
  position: relative;
  top: 10px;
}

.net-asset table {
  width: 100%;
}

.net-asset table thead th {
  font-size: 20px;
  padding: 5px;
  text-align: left;
  border-bottom: 1px solid #dadada;
}

.net-asset table td {
  border-bottom: 1px solid #dadada;
  padding: 10px;
}

.suggested-funds .title {
  position: relative;
  width: 100%;
}

.suggested-box {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #9f9f9f;
  box-sizing: border-box;
  border-radius: 5px;
  width: 349px;
  margin: auto;
}

.suggested-box .for-aggresive {
  background: #256141;
  color: white;
  padding: 5px;
  width: 200px;
  border-radius: 10px;
  font-size: 11px;
}

.suggested-box .min {
  margin: 10px 0px;
}

.suggested-box .min span {
  padding-bottom: 10px;
}

.help button {
  background: #256141;
  color: white;
  position: absolute;
  right: 50px;
  top: 0;
}

.help button:hover {
  background: #256141;
}

.copyright {
  margin: auto;
  text-align: center;
}